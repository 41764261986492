// https://www.svgrepo.com/
const Logo = () => {
  return (
    <svg width="48px" height="48px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
	<polygon id="layers-a" points="17.014 3 8.892 7.983 .77 3.314 4.143 .351 8.892 3 13.823 .351"/>
	<path id="layers-c" d="M1.46887113,8 L1.00772212,7.73648628 C-0.335907374,6.968698 -0.335907374,5.031302 1.00772212,4.26351372 L8.00772212,0.263513716 C8.62258746,-0.0878379053 9.37741254,-0.0878379053 9.99227788,0.263513716 L16.9922779,4.26351372 C18.3359074,5.031302 18.3359074,6.968698 16.9922779,7.73648628 L16.5311289,8 L16.9922779,8.26351372 C18.3359074,9.031302 18.3359074,10.968698 16.9922779,11.7364863 L16.5311289,12 L16.9922779,12.2635137 C18.3359074,13.031302 18.3359074,14.968698 16.9922779,15.7364863 L9.99227788,19.7364863 C9.37741254,20.0878379 8.62258746,20.0878379 8.00772212,19.7364863 L1.00772212,15.7364863 C-0.335907374,14.968698 -0.335907374,13.031302 1.00772212,12.2635137 L1.46887113,12 L1.00772212,11.7364863 C-0.335907374,10.968698 -0.335907374,9.031302 1.00772212,8.26351372 L1.46887113,8 Z M14.5155644,9.15175111 L9.99227788,11.7364863 C9.37741254,12.0878379 8.62258746,12.0878379 8.00772212,11.7364863 L3.48443556,9.15175111 L2,10 L9,14 L16,10 L14.5155644,9.15175111 Z M3.48443556,13.1517511 L2,14 L9,18 L16,14 L14.5155644,13.1517511 L9.99227788,15.7364863 C9.37741254,16.0878379 8.62258746,16.0878379 8.00772212,15.7364863 L3.48443556,13.1517511 Z M9,2 L2,6 L9,10 L16,6 L9,2 Z"/>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(3 2)">
	<g transform="translate(0 7)">
	  <mask id="layers-b" fill="#ffffff">
            <use xlinkHref="#layers-a"/>
	  </mask>
	  <use fill="#D8D8D8" xlinkHref="#layers-a"/>
	  <g fill="#FFA0A0" mask="url(#layers-b)">
            <rect width="24" height="24" transform="translate(-3 -9)"/>
	  </g>
	</g>
	<mask id="layers-d" fill="#ffffff">
	  <use xlinkHref="#layers-c"/>
	</mask>
	<use fill="#000000" fillRule="nonzero" xlinkHref="#layers-c"/>
	<g fill="#7600FF" mask="url(#layers-d)">
	  <rect width="24" height="24" transform="translate(-3 -2)"/>
	</g>
      </g>
    </svg>
  );
};

export default Logo;
