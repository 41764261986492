const LikedHeart = ({ props, onClick }) => {
  return (
    <svg
      className={`${onClick ? 'pointer' : ''}`}
      onClick={onClick}
      width="34"
      height="34"
      viewBox="0 0 256 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M240 94C240 164 136.21 220.66 131.79 223C130.625 223.627 129.323 223.955 128 223.955C126.677 223.955 125.375 223.627 124.21 223C119.79 220.66 16 164 16 94C16.0185 77.5623 22.5566 61.8031 34.1798 50.1798C45.8031 38.5566 61.5623 32.0185 78 32C98.65 32 116.73 40.88 128 55.89C139.27 40.88 157.35 32 178 32C194.438 32.0185 210.197 38.5566 221.82 50.1798C233.443 61.8031 239.981 77.5623 240 94Z"
        fill="#B22222"
      />
    </svg>
  );
};

export default LikedHeart;
