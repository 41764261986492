const CheckedStar = ({ props, onClick, className }) => {
  return (
    <svg
      className={`${className} pointer`}
      width="34"
      height="34"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_128_5)">
        <path
          d="M34 16.78C34.3717 16.5031 34.6474 16.1169 34.7884 15.6754C34.9295 15.2339 34.9289 14.7594 34.7866 14.3183C34.6444 13.8772 34.3676 13.4917 33.9952 13.2158C33.6228 12.94 33.1734 12.7876 32.71 12.78L23.71 12.44C23.6658 12.4369 23.6234 12.4212 23.5879 12.3945C23.5524 12.3679 23.5253 12.3316 23.51 12.29L20.4 3.88999C20.2438 3.46263 19.96 3.09361 19.587 2.83289C19.2141 2.57217 18.7701 2.43234 18.315 2.43234C17.86 2.43234 17.416 2.57217 17.043 2.83289C16.6701 3.09361 16.3863 3.46263 16.23 3.88999L13.13 12.32C13.1147 12.3616 13.0877 12.3979 13.0522 12.4245C13.0167 12.4512 12.9743 12.4669 12.93 12.47L3.93002 12.81C3.46663 12.8176 3.01722 12.97 2.64482 13.2458C2.27241 13.5217 1.99568 13.9072 1.85343 14.3483C1.71118 14.7894 1.71055 15.2639 1.85161 15.7054C1.99267 16.1469 2.26836 16.5331 2.64002 16.81L9.70002 22.36C9.73536 22.3878 9.76177 22.4254 9.77598 22.468C9.7902 22.5107 9.79161 22.5565 9.78002 22.6L7.35002 31.21C7.2241 31.6484 7.2363 32.115 7.38496 32.5463C7.53362 32.9776 7.81153 33.3526 8.18087 33.6203C8.55022 33.888 8.99307 34.0354 9.44918 34.0425C9.90529 34.0497 10.3525 33.9161 10.73 33.66L18.19 28.66C18.2268 28.6346 18.2704 28.621 18.315 28.621C18.3597 28.621 18.4033 28.6346 18.44 28.66L25.9 33.66C26.2724 33.9248 26.7181 34.0671 27.175 34.0671C27.632 34.0671 28.0776 33.9248 28.45 33.66C28.8195 33.3948 29.0976 33.0216 29.2463 32.5918C29.3949 32.1621 29.4067 31.6968 29.28 31.26L26.83 22.62C26.817 22.5766 26.8178 22.5303 26.8321 22.4873C26.8464 22.4444 26.8736 22.4069 26.91 22.38L34 16.78Z"
          fill="#FFC700"
        />
      </g>
      <defs>
        <clipPath id="clip0_128_5">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckedStar;
