const Heart = ({ props, onClick }) => {
  return (
    <svg
      className="pointer"
      onClick={onClick}
      width="34"
      height="34"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.624 4.42401C3.965 5.18201 2.75 6.98601 2.75 9.13701C2.75 11.334 3.65 13.028 4.938 14.48C6.001 15.676 7.287 16.668 8.541 17.634C8.839 17.864 9.135 18.093 9.426 18.322C9.952 18.737 10.421 19.1 10.874 19.365C11.326 19.629 11.69 19.75 12 19.75C12.31 19.75 12.674 19.63 13.126 19.365C13.579 19.1 14.048 18.737 14.574 18.322C14.864 18.092 15.161 17.864 15.459 17.634C16.713 16.668 17.999 15.676 19.062 14.48C20.351 13.028 21.25 11.334 21.25 9.13701C21.25 6.98701 20.035 5.18201 18.376 4.42401C16.764 3.68701 14.598 3.88201 12.54 6.02101C12.47 6.09359 12.3862 6.15133 12.2934 6.19077C12.2006 6.2302 12.1008 6.25053 12 6.25053C11.8992 6.25053 11.7994 6.2302 11.7066 6.19077C11.6138 6.15133 11.53 6.09359 11.46 6.02101C9.402 3.88201 7.236 3.68701 5.624 4.42401ZM12 4.46001C9.688 2.39001 7.099 2.10001 5 3.05901C2.786 4.07401 1.25 6.42601 1.25 9.13801C1.25 11.803 2.36 13.837 3.817 15.477C4.983 16.79 6.41 17.889 7.671 18.859C7.957 19.079 8.234 19.293 8.497 19.501C9.01 19.905 9.56 20.335 10.117 20.661C10.674 20.986 11.31 21.251 12 21.251C12.69 21.251 13.326 20.986 13.883 20.661C14.441 20.335 14.99 19.905 15.503 19.501C15.7769 19.2852 16.0522 19.0712 16.329 18.859C17.589 17.889 19.017 16.789 20.183 15.477C21.64 13.837 22.75 11.803 22.75 9.13801C22.75 6.42601 21.215 4.07401 19 3.06101C16.901 2.10101 14.312 2.39101 12 4.46001Z"
        fill="#B22222"
      />
    </svg>
  );
};

export default Heart;
