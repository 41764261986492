import React from 'react';

const Zap = ({ zapRef, dataNpub, dataRelays, onClick }) => {
  return (
    <svg
      style={{ cursor: 'pointer' }}
      data-npub={dataNpub}
      ref={zapRef}
      data-relays={dataRelays}
      onClick={onClick}
      className="svg-inline--fa fa-pointer"
      width="34"
      height="34"
      viewBox="0 0 24 24"
      fill=""
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7156 1.32891C15.9453 1.12864 16.2365 1.01261 16.541 0.999939C16.8455 0.987271 17.1453 1.07872 17.3909 1.25922C17.6365 1.43972 17.8133 1.6985 17.8921 1.99292C17.9709 2.28734 17.9471 2.59983 17.8246 2.87891L15.1466 8.99991H19.3076C20.9306 8.99991 21.6796 11.0159 20.4506 12.0749L8.10159 22.7209C7.90561 22.8919 7.6574 22.9912 7.39758 23.0027C7.13776 23.0142 6.88174 22.9371 6.67145 22.7841C6.46116 22.6311 6.30905 22.4112 6.24004 22.1605C6.17103 21.9097 6.1892 21.643 6.29159 21.4039L8.99559 14.9999H4.67359C3.05459 14.9999 2.30359 12.9919 3.52559 11.9299L15.7156 1.32991V1.32891ZM16.1676 2.92391L4.50959 13.0609C4.47103 13.0943 4.4436 13.1387 4.43099 13.1881C4.41838 13.2375 4.42119 13.2896 4.43904 13.3374C4.45689 13.3852 4.48892 13.4264 4.53085 13.4554C4.57277 13.4845 4.62258 13.5 4.67359 13.4999H10.1236C10.2474 13.4995 10.3695 13.5299 10.4787 13.5882C10.588 13.6466 10.681 13.7311 10.7496 13.8343C10.8182 13.9374 10.8601 14.056 10.8716 14.1793C10.8831 14.3026 10.8639 14.4269 10.8156 14.5409L8.25659 20.6069L19.4716 10.9389C19.5101 10.9055 19.5376 10.8611 19.5502 10.8117C19.5628 10.7623 19.56 10.7102 19.5421 10.6624C19.5243 10.6146 19.4923 10.5735 19.4503 10.5444C19.4084 10.5154 19.3586 10.4998 19.3076 10.4999H13.9996C13.8751 10.4998 13.7526 10.4688 13.6431 10.4095C13.5336 10.3503 13.4405 10.2647 13.3724 10.1605C13.3042 10.0564 13.2631 9.93685 13.2526 9.81279C13.2422 9.68872 13.2628 9.56402 13.3126 9.44991L16.1676 2.92391Z"
        fill="#9747FF"
      />
    </svg>
  );
};

export default Zap;
