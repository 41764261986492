const Edit = ({ props, onClick }) => {
  return (
    <svg
      onClick={onClick}
      width="30"
      height="27"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4549 5.41607C21.5499 5.56028 21.5922 5.73286 21.5747 5.90466C21.5573 6.07647 21.481 6.23697 21.3589 6.35907L12.1659 15.5511C12.0718 15.6451 11.9545 15.7124 11.8259 15.7461L7.99689 16.7461C7.87032 16.7791 7.73732 16.7784 7.61109 16.7441C7.48485 16.7099 7.36978 16.6432 7.27729 16.5507C7.18479 16.4582 7.1181 16.3431 7.08382 16.2169C7.04955 16.0906 7.04888 15.9576 7.08189 15.8311L8.08189 12.0031C8.11109 11.8882 8.16616 11.7814 8.24289 11.6911L17.4699 2.47007C17.6105 2.32962 17.8011 2.25073 17.9999 2.25073C18.1986 2.25073 18.3893 2.32962 18.5299 2.47007L21.3589 5.29807C21.3948 5.33408 21.4269 5.37361 21.4549 5.41607ZM19.7679 5.82807L17.9999 4.06107L9.48189 12.5791L8.85689 14.9721L11.2499 14.3471L19.7679 5.82807Z"
        fill="#761BFF"
      />
      <path
        d="M19.6406 17.1599C19.9139 14.8238 20.0012 12.4698 19.9016 10.1199C19.8994 10.0645 19.9087 10.0093 19.9288 9.95769C19.9489 9.90608 19.9795 9.85916 20.0186 9.81989L21.0026 8.83589C21.0295 8.80885 21.0636 8.79014 21.1008 8.78203C21.1381 8.77392 21.1769 8.77674 21.2126 8.79015C21.2483 8.80356 21.2794 8.827 21.3021 8.85764C21.3248 8.88828 21.3381 8.92483 21.3406 8.96289C21.5258 11.7541 21.4555 14.5564 21.1306 17.3349C20.8946 19.3569 19.2706 20.9419 17.2576 21.1669C13.7629 21.5539 10.2362 21.5539 6.74157 21.1669C4.72957 20.9419 3.10457 19.3569 2.86857 17.3349C2.45397 13.7903 2.45397 10.2095 2.86857 6.66489C3.10457 4.64289 4.72857 3.05789 6.74157 2.83289C9.39394 2.53877 12.0663 2.46752 14.7306 2.61989C14.7687 2.62262 14.8052 2.63623 14.8359 2.6591C14.8665 2.68196 14.8899 2.71313 14.9034 2.74891C14.9169 2.78468 14.9198 2.82357 14.9119 2.86096C14.9039 2.89835 14.8854 2.93268 14.8586 2.95989L13.8656 3.95189C13.8267 3.99064 13.7803 4.02101 13.7292 4.04113C13.6781 4.06125 13.6234 4.0707 13.5686 4.06889C11.3453 3.99331 9.11952 4.07853 6.90857 4.32389C6.26251 4.39539 5.65942 4.68261 5.19672 5.13914C4.73403 5.59567 4.43874 6.19485 4.35857 6.83989C3.95762 10.2682 3.95762 13.7316 4.35857 17.1599C4.43874 17.8049 4.73403 18.4041 5.19672 18.8606C5.65942 19.3172 6.26251 19.6044 6.90857 19.6759C10.2636 20.0509 13.7356 20.0509 17.0916 19.6759C17.7376 19.6044 18.3407 19.3172 18.8034 18.8606C19.2661 18.4041 19.5604 17.8049 19.6406 17.1599Z"
        fill="#761BFF"
      />
    </svg>
  );
};

export default Edit;
